import styled, { css } from 'styled-components'

export const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const PreFooter = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.rem(20, 0)};
  background-color: ${({ theme }) => theme.colors.primary.default};
  color: ${({ theme }) => theme.colors.primary.light6};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: ${theme.rem(40, 0)};
  `)};
`

export const PreFooterItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${({ theme }) => theme.rem(20, 0, 20, 0)};
  border-top: 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light2};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    border-top: 1px solid ${theme.colors.primary.light2};
    padding: ${theme.rem(40, 0)};
  `)};
`

export const PreFooterItemWrapper = styled.div`
  padding-right: ${({ theme }) => theme.rem(60)};
`

export const PreFooterItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.grayscale.white};
  margin-bottom: ${({ theme }) => theme.rem(15)};
`

export const PreFooterItemLink = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ theme }) => theme.rem(15)};
`

export const FooterTop = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.rem(40, 0)};
  background-color: ${({ theme }) => theme.colors.primary.default};
  color: ${({ theme }) => theme.colors.grayscale.white};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    padding: ${theme.rem(80, 0)};
  `)};
`

export const FooterTopLogos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.rem(80)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    margin-bottom: 0;
  `)};

  // i:not(:last-child) {
  //   margin-bottom: 30px;
  // }
`

export const FooterBottom = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary.dark1};
  color: ${({ theme }) => theme.colors.primary.light4};
  padding-bottom: env(safe-area-inset-bottom);
`

export const FooterBottomItem = styled.div`
  margin: ${({ theme }) => theme.rem(20, 0)};
`

export const FooterBottomLink = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: ${({ theme }) => theme.rem(20)};
  border-top: 1px solid ${({ theme }) => theme.colors.primary.light3};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    width: auto;
    border-top: 0;
    margin: ${theme.rem(20, 0)};
  `)};

  & a:not(:last-child),
  & button:not(:last-child) {
    margin-right: ${({ theme }) => theme.rem(48)};
  }
`

export const FooterBottomSocials = styled.div`
  display: flex;
  margin: ${({ theme }) => theme.rem(20, 0)};
`

export const FooterBottomSocialsItem = styled.div`
  display: flex;

  &:not(:last-child) {
    margin-right: ${({ theme }) => theme.rem(16)};
  }

  & svg {
    width: 25px;
    height: 25px;
  }

  & a {
    color: ${({ theme }) => theme.colors.primary.light4};
  }
`

export const FooterMenu = styled.div``

export const FooterMenuTitle = styled.div`
  margin: ${({ theme }) => theme.rem(0, 0, 16, 0)};
`

export const FooterMenuList = styled.div`
  margin-bottom: ${({ theme }) => theme.rem(40)};
`

export const FooterMenuListItem = styled.div`
  color: ${({ theme }) => theme.colors.primary.light4};

  &:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.rem(16)};
  }
`
