const Footer = ({ data }) => {
  const { preFooterLinks, topLinks, infoTexts, bottomLinks, social, footerTopLogos } = data

  let accordionLinks = []
  accordionLinks = topLinks.map((el, i) => {
    let item = {}
    item.id = el.id
    item.variant = 'links'
    item.title = el.name
    item.links = el.links

    return item
  })

  const normalizedData = {
    preFooterLinks,
    topLinks,
    accordionLinks,
    infoTexts,
    bottomLinks,
    social,
    footerTopLogos,
  }

  return normalizedData
}

export default Footer
