import React from 'react'

import { Button, Icon, InnerContent } from '@/atoms'
import { Paragraph, Heading } from '@/atoms/Typography'
import { Container, Row, Col, Visible, SpacerBase } from '@/atoms/Grid'
import Accordion from '@/molecules/Accordion'

import * as S from './styles'
import Link from 'next/link'

// const footerTopLogos = [
//   {
//       id: "d89a9452-d651-4591-82ab-63122409537f",
//       label: null,
//       logos: [
//           {
//               id: "9450c18d-6307-489f-aeca-9a8a92b92f71",
//               logo: "logo-assicurazioni-light",
//               cta: null
//           },
//           {
//               id: "41da5d85-bbca-4e9c-8b54-10a6c58dab5c",
//               logo: "logo-brandmark-light",
//               cta: null
//           }
//       ]
//   },
//   {
//       id: "c5945e8d-74da-4339-b4a4-706d00dbd33b",
//       label: "altri siti utili",
//       logos: [
//           {
//               id: "0c42c7ff-ac60-4d34-92f5-74b8f9fed715",
//               logo: "logo-brandmark-light",
//               cta: null
//           }
//       ]
//   }
// ]

const Footer = ({ id, data }) => {
  const {
    preFooterLinks,
    topLinks,
    accordionLinks,
    infoTexts,
    bottomLinks,
    social,
    footerTopLogos = [],
  } = data
  console.log({footerTopLogos})
  return (
    <S.StyledFooter id={id}>
      <S.PreFooter>
        <Container>
          <Row>
            {preFooterLinks &&
              preFooterLinks.map((el) => {
                return (
                  <Col
                    key={el.id}
                    xs={12}
                    md={4}
                  >
                    <S.PreFooterItem>
                      <S.PreFooterItemWrapper>
                        {el.title && (
                          <S.PreFooterItemTitle>
                            <Heading
                              typo={'displayXS'}
                              bold
                            >
                              {el.title}
                            </Heading>
                          </S.PreFooterItemTitle>
                        )}
                        {el.description && (
                          <Paragraph typo={'paragraph2'}>
                            {el.description}
                          </Paragraph>
                        )}
                      </S.PreFooterItemWrapper>
                      {el.link && (
                        <S.PreFooterItemLink>
                          {el.link.label && (
                            <Paragraph typo={'paragraph2'}>
                              {el.link.label}
                            </Paragraph>
                          )}
                          <Button
                            ariaLabel={
                              el.link?.ariaLabel
                                ? el.link?.ariaLabel
                                : el.link.label
                            }
                            href={el.link.href}
                            target={el.link.target}
                            primary
                            inheritedColorScheme='dark'
                            iconEnd={'arrow-right-big'}
                          />
                        </S.PreFooterItemLink>
                      )}
                    </S.PreFooterItem>
                  </Col>
                )
              })}
          </Row>
        </Container>
      </S.PreFooter>
      <S.FooterTop>
        <Container>
          <Row>
            <Col
              xs={12}
              md={3}
            >
              <S.FooterTopLogos>
                {footerTopLogos.length > 0 ? footerTopLogos.map(({ label, logos, id }, index) => {
                  return (
                    <React.Fragment key={id}>
                      { label && label !== '' ? (
                        <React.Fragment>
                          <Paragraph typo={'label1'} uppercase bold content={label} />
                          <SpacerBase xs={3} />
                        </React.Fragment>
                      ) : null }
                      {logos.map(({ logo, id, cta }, index) => {
                        if (cta) {
                          return (
                            <React.Fragment key={id}>
                              <Link href={cta.href} target={cta.target} rel='noopener noreferrer' aria-label={cta.ariaLabel}>
                                <Icon name={logo} ariaLabel={logo} unlockedProportions useIconColor />
                              </Link>
                              {index < logos.length - 1 ? <SpacerBase xs={3} /> : null}
                            </React.Fragment>
                          )
                        } else {
                          return (
                            <React.Fragment key={id}>
                              <Icon name={logo} ariaLabel={logo} unlockedProportions useIconColor />
                              {index < logos.length - 1 ? <SpacerBase xs={3} /> : null}
                            </React.Fragment>
                          )
                        }
                      })}
                      {index < footerTopLogos.length - 1 ? <SpacerBase xs={4} /> : null}
                    </React.Fragment>
                  )
                }) : null}

                {/* <Icon
                  name={'logo-mixed-light'}
                  ariaLabel='Logo Arca vita Arca assicurazioni'
                  unlockedProportions
                  useIconColor
                />
                <SpacerBase xs={4} />
                <Paragraph
                  content='Altri siti utili'
                  typo='label1'
                  uppercase
                  bold
                />
                <SpacerBase xs={3} />
                <Link
                  href='http://www.arcaintl.com/'
                  aria-label='Vai al sito di Arca vita Arca assicurazioni Internazionale'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon
                    ariaLabel='Logo Arca vita Arca assicurazioni Internazionale'
                    name={'logo-vita-international-light'}
                    unlockedProportions
                    useIconColor
                  />
                </Link>
                <SpacerBase xs={3} />
                <Link
                  href='http://www.unipol.it/'
                  aria-label='Vai al sito di Unipol'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  <Icon
                    ariaLabel='Logo Unipol'
                    name={'logo-unipol-color'}
                    unlockedProportions
                    useIconColor
                  />
                </Link> */}
              </S.FooterTopLogos>
            </Col>
            <Visible
              md
              lg
              xl
              xxl
            >
              {topLinks &&
                topLinks.map((el, i) => {
                  return (
                    <Col
                      key={el.id}
                      xs={12}
                      md={2}
                      $offset={
                        i == 0 ? { md: 1 } : i % 4 == 0 ? { md: 4 } : null
                      }
                    >
                      <S.FooterMenu>
                        <S.FooterMenuTitle>
                          <Paragraph
                            typo='paragraph2'
                            bold
                          >
                            {el.name}
                          </Paragraph>
                        </S.FooterMenuTitle>
                        <S.FooterMenuList>
                          {el.links.map((el) => (
                            <S.FooterMenuListItem key={el.id}>
                              <Button
                                {...el}
                                quaternary
                                inheritedColorScheme='dark'
                              />
                            </S.FooterMenuListItem>
                          ))}
                        </S.FooterMenuList>
                      </S.FooterMenu>
                    </Col>
                  )
                })}
            </Visible>
            <Visible
              xs
              sm
            >
              <Col xs={12}>
                <Accordion list={accordionLinks} />
              </Col>
            </Visible>
          </Row>
        </Container>
      </S.FooterTop>
      <S.FooterBottom>
        <Container>
          <Row>
            {infoTexts &&
              infoTexts.map((el, i) => (
                <Col
                  key={el.id}
                  xs={12}
                  md={5}
                  $offset={i % 2 == 0 ? null : { md: 2 }}
                >
                  <S.FooterBottomItem>
                    <InnerContent
                      typo={'link1'}
                      inheritedColorScheme='dark'
                    >
                      {el.text}
                    </InnerContent>
                  </S.FooterBottomItem>
                </Col>
              ))}
            <Col
              xs={12}
              lg={5}
              $offset={{ lg: 2 }}
              $direction={{ xs: 'column', lg: 'row' }}
              justify={{ xs: 'center', lg: 'space-between' }}
              align={{ xs: 'flex-start', lg: 'flex-end' }}
            >
              <S.FooterBottomLink>
                {bottomLinks &&
                  bottomLinks.map((el) => (
                    <Button
                      key={el.id}
                      {...el}
                      quaternary
                      inheritedColorScheme='dark'
                    />
                  ))}
              </S.FooterBottomLink>
              <S.FooterBottomSocials>
                {social &&
                  social.map((el) => (
                    <S.FooterBottomSocialsItem key={el.id}>
                      <Button
                        href={el.href}
                        target='_blank'
                        iconEnd={el.type}
                        ariaLabel={`Vai alla pagina social Arca di ${el.type}`}
                        tertiary
                        inheritedColorScheme='dark'
                      />
                    </S.FooterBottomSocialsItem>
                  ))}
              </S.FooterBottomSocials>
            </Col>
          </Row>
        </Container>
      </S.FooterBottom>
    </S.StyledFooter>
  )
}

export default Footer
