import React, { Fragment } from 'react'

import { Button } from '@/atoms'
import { Paragraph } from '@/atoms/Typography'
import { SpacerBase } from '@/atoms/Grid'

import * as S from './styles'
import { getHeadingID } from '@utils/getHeadingID'

const Document = ({ title, description, ctas = [], renderedBy }) => {
  return (
    <S.DocumentCard renderedBy={renderedBy}>
      {title ? (
        <Paragraph
          typo='paragraphLG'
          bold
        >
          {title}
        </Paragraph>
      ) : null}
      {description ? (
        <>
          <SpacerBase xs={2} />
          <Paragraph typo='paragraph2'>{description}</Paragraph>
        </>
      ) : null}
      {ctas && ctas.length
        ? ctas.map((cta, i) =>
            cta ? (
              <Fragment key={`card-document-cta-${getHeadingID(title, i)}`}>
                <SpacerBase xs={3} />
                <Button
                  {...cta}
                  ariaLabel={`${cta.label}${title ? ' ' + title : ''}`}
                  tertiary={true}
                  inheritedColorScheme='primaryLight'
                  iconStart={'download'}
                  uppercase={true}
                />
              </Fragment>
            ) : null
          )
        : null}
    </S.DocumentCard>
  )
}

export default Document
