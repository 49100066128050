import { SpacerBase } from '@/atoms/Grid'
// import Card from '@/molecules/Card'
import { Carousel } from '@/molecules'

import { StyledTabsContentInner, StyledTabsDocWrapper } from './styles'

const ContentCard = ({ documents }) => {
  const carouselItems =
    documents.length > 0
      ? documents.map((item) => {
          return {
            type: 'document',
            item,
          }
        })
      : null

  return documents.length > 0 ? (
    <StyledTabsContentInner>
      <SpacerBase
        xs={4}
        md={0}
      />
      <StyledTabsDocWrapper>
        <Carousel items={carouselItems} />
      </StyledTabsDocWrapper>
      {/* {documents.map((item, i) => {
        return (
            <Card
              data={item}
              type='document'
            />
        )
      })} */}
    </StyledTabsContentInner>
  ) : null
}

export default ContentCard
