import { Heading, Paragraph } from '@/atoms/Typography/styles'
import styled, { css } from 'styled-components'

export const StyledCard = styled.div``

/**
 * Tab
 */
export const StyledTabCard = styled.div`
  padding: ${({ theme }) => theme.rem(20, 32)};
  background-color: ${({ theme }) => theme.colors.secondary.light5};
  border-radius: ${({ theme }) => theme.rem(8)};

  i {
    font-size: ${({ theme }) => theme.rem(32)};
  }
`

/**
 * Hero
 */
export const HeroCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${({ theme }) => theme.rem(24, 10)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: ${({ theme }) => theme.rem(8)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    flex-direction: row;
    align-items: center;
    padding: ${theme.rem(24, 20)};
  `)};
`

export const HeroCardIcon = styled.div`
  svg {
    width: ${({ theme }) => theme.rem(48)};
    height: ${({ theme }) => theme.rem(48)};
  }
`

export const HeroCardText = styled.div`
  margin: ${({ theme }) => theme.rem(20, 0)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    margin: ${theme.rem(0, 88, 0, 20)};
  `)};
`

/**
 * Bullet Point
 */
export const BulletPointCardWrap = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-right: 0;
`

export const BulletPointCard = styled.div`
  width: 100%;
  height: 100%;

  ${({ theme, reverse, colorScheme }) =>
    reverse &&
    css`
      padding: 20px 0;

      ${BulletPointCardWrap} {
        border-right: 0.5px solid
          ${theme.colors.surface[colorScheme].borderColor1};

        ${({ theme, reverse }) =>
          reverse &&
          theme.mediaQuery.md(`
        padding-right: ${theme.rem(24)};
      `)};
      }
    `}
`

export const BulletPointHeader = styled.div`
  display: flex;
  align-items: center;

  ${({ reverse }) =>
    reverse &&
    css`
      flex-direction: column;
      align-items: flex-start;

      i {
        margin-bottom: ${({ theme }) => theme.rem(20)};
      }
    `}

  i {
    width: ${({ theme }) => theme.rem(32)};
    height: ${({ theme }) => theme.rem(32)};
    margin-right: ${({ theme }) => theme.rem(8)};
  }
`

/**
 * Related Product
 */
export const RelatedProductCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.rem(0)};
  margin: ${({ theme }) => theme.rem(16, 0)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    flex-direction: row;
    padding: ${theme.rem(16, 32, 16, 0)};
  `)};

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
      ${theme.mediaQuery.lg(`
      border-right: 0.5px solid ${theme.colors.surface[colorScheme].borderColor2};
    `)};
    `}
`

export const RelatedProductImage = styled.div`
  width: 160px;
  height: 160px;
  flex: 0 0 160px;
  position: relative;
  margin-right: ${({ theme }) => theme.rem(0)};

  ${({ theme }) =>
    theme.mediaQuery.md(`
    margin-right: ${theme.rem(24)};
  `)};

  img {
    object-fit: contain;
  }
`

export const RelatedProductText = styled.div`
  flex: 1;
  text-align: center;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    text-align: left;
  `)};
`

/**
 * Post Full
 */
export const PostCardImage = styled.div`
  min-height: ${({ theme }) => theme.rem(320)};
  position: relative;
  border-radius: ${({ theme }) => theme.rem(8)};
  overflow: hidden;

  ${({ theme }) =>
    theme.mediaQuery.md(`
    min-height: ${theme.rem(380)};
  `)};

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(51, 141, 243, 0.1),
      rgba(51, 141, 243, 0.1)
    );
    opacity: 0;
    z-index: 1;
    transition: all 0.3s ease-in-out;
  }

  img {
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
  }
`

export const PostCardTitle = styled.div`
  position: relative;
`

export const PostCardExcerpt = styled.div``

export const PostCard = styled.div`
  width: 100%;
  margin-top: ${({ theme }) => theme.rem(40)};

  ${PostCardTitle} {
    ${Heading} {
      display: inline;
      background-image: linear-gradient(0deg, currentColor, currentColor);
      background-position: 100% 100%;
      background-repeat: no-repeat;
      background-size: 0 0.5px;
      border-radius: 0;
      transition: background-size 0.3s ease-in-out;
      transform: translateZ(0);
      -webkit-font-smoothing: subpixel-antialiased;
    }
  }

  ${PostCardExcerpt} {
    ${Paragraph} {
      color: ${({ theme }) => theme.colors.primary.light2};
    }
  }

  a {
    &:hover,
    &:focus {
      ${PostCardImage} {
        &::before {
          opacity: 1;
        }

        img {
          transform: scale(1.2);
        }
      }

      ${PostCardTitle} {
        ${Heading} {
          background-size: 100% 0.5px;
          outline-width: 0;
        }
      }
    }
  }
`

export const PostCardDate = styled.div`
  text-transform: capitalize;
`

/**
 * Post Min
 */
export const PostMinCard = styled.div`
  width: 100%;
  margin-top: 0;
`

export const PostMinCardImage = styled(PostCardImage)`
  min-height: ${({ theme }) => theme.rem(176)};
`

export const PostMinCardTag = styled.div`
  display: inline-block;
  padding: ${({ theme }) => theme.rem(8)};
  background-color: ${({ theme }) => theme.colors.brand.teal.light1};
  color: ${({ theme }) => theme.colors.secondary.dark1};
  border-radius: 4px;
  text-transform: uppercase;
`

/**
 * Document
 */
export const DocumentCard = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: ${({ theme }) => theme.rem(44, 16)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.rem(24)};

  ${({ renderedBy }) =>
    renderedBy === 'slider' &&
    css`
      height: 100%;
      border: 1px solid ${({ theme }) => theme.colors.surface.white.borderColor2};
      margin-bottom: 0;
    `}
`

/**
 * Filial
 */
export const FilialCard = styled.div`
  padding: ${({ theme }) => theme.rem(12, 24)};
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  border-radius: 8px;
  margin-bottom: ${({ theme }) => theme.rem(16)};
  box-shadow:
    0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);

  a,
  button {
    margin-top: ${({ theme }) => theme.rem(16)};
  }
`

/**
 * BaseCard
 */
export const BaseCardText = styled.div`
  width: 100%;
  flex: 1;
`

export const BaseCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: ${({ theme }) => theme.rem(24, 0)};

  ${({ theme, colorScheme }) =>
    colorScheme &&
    css`
    ${BaseCardText} {
      ${Paragraph} {
        color: ${theme.colors.surface[colorScheme].borderColor3}};
      }
    }
  `}
`

export const BaseCardImage = styled.div`
  width: 100%;
  height: 420px;
  position: relative;
  margin-bottom: ${({ theme }) => theme.rem(16)};

  ${({ theme }) =>
    theme.mediaQuery.md(
      `
    height: 360px;
  `,
      true
    )};

  img {
    object-fit: contain;
  }
`
